import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const Background = props => {
  if (!props.fluid)
    return props.children

  const stack = [
    `linear-gradient(rgba(255, 155, 6, 0.9), rgba(255, 155, 6, 0.9))`,
    props.fluid
  ]

  return <BackgroundImage {...props} fluid={stack} />
}

export default Background
