import React from 'react'
import { Flex, Box } from 'rebass'
import Background from './Background'

const Hero = ({ children, fluid }) => (
    <Box variant='hero'>
        <Background fluid={fluid}>
            <Box variant='fixed'>
                <Flex
                    justifyContent="center"
                    flexDirection="column"
                    className="content"
                >
                    {children}
                </Flex>
            </Box>
        </Background>
    </Box>
)

export default Hero
